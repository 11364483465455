import React, { Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import "semantic-ui-css/semantic.min.css";
import { Checkbox, Dropdown } from "semantic-ui-react";
import { setTurbineZoneShow } from "../../redux/windTurbine";
import "../NavBar.css";
import DropDownCustomized from "../dropDownComponent/DropDown";
import DropDownDaylight from "../dropDownComponent/DropDownDaylight";
import DropDownEnergyData from "../dropDownComponent/DropDownEnergyData";
import DropDownEnergyGeneration from "../dropDownComponent/DropDownEnergyGeneration";
import DropDownFloors from "../dropDownComponent/DropDownFloors";
import DropDownShading from "../dropDownComponent/DropDownShading";
import DropDownSunHours from "../dropDownComponent/DropDownSunHours";
import { hasShadingProgression } from "../utils";
import { updatePVDataFilter } from "../../redux/pvData";
import { getOldProjectId } from "../utility/hardconding";

export const sunsensors = [
  "sun hours",
  "direct energy",
  "diffuse energy",
  "global energy",
];

const NavBar = (props) => {
  var windRiskSelector = useSelector((state) => state.shadingSystem.value);
  var globalSceneValue = useSelector((state) => state.sceneVisibility.value);
  var windTurbineSelector = useSelector((state) => state.windTurbine.value);

  var surroundingSceneValue = useSelector(
    (state) => state.sceneVisibility.surroundingValue
  );
  var solarSensorSelector = useSelector((state) => state.sensorSolars.value);
  var windDirectionSelector = useSelector((state) => state.windDirection.value);

  var allProejctParameters = useSelector((state) => state.sceneVar);
  var floors = allProejctParameters.floorsList;
  var winddirection = allProejctParameters.windDirectionList;
  var windRisk = allProejctParameters.windRisk;
  var energyValueData = allProejctParameters.energyValueList;
  const pvPanelsData = allProejctParameters.pvPanelsData;
  const newProjectId = localStorage.getItem("project_id");
  const project_id = getOldProjectId(newProjectId)
  // const project_id = localStorage.getItem("project_id");

  const targetBuildingsList = [
    // { id: 0, title: "Target Building" },
    { id: 1, title: "External Walls" },
    { id: 2, title: "Internal Walls" },
    { id: 3, title: "Ceilings" },
    { id: 4, title: "Windows" },
    { id: 5, title: "Rooms" },
    { id: 6, title: "Doors" },
    { id: 7, title: "Stairs" },
    { id: 8, title: "Rooftops" },
    { id: 9, title: "PV Panels" },
  ];
  const surroundingList = [
    { id: 0, title: "Buildings" },
    { id: 1, title: "Infrastructure" },
    { id: 2, title: "Vegetation" },
    { id: 3, title: "Terrian" },
  ];

  var shadingSystem = [
    // { id: 1, title: "Shading Demand" },
    // { id: 2, title: "Sensor Positioning" },
  ];

  if (windRisk) shadingSystem.push(
    { id: 0, title: "Wind Risk Data" }
  )
  if (hasShadingProgression(project_id, newProjectId)) {
    shadingSystem.push(
      { id: 2, title: "Shading Progression" },
    )
  }

  const sensorPosition = [
    { id: 3, title: "Zone One", zone: 1 },
    { id: 4, title: "Zone Two", zone: 2 },
    // { id: 1, title: "Top 10" },
  ];

  const sensorPosition14 = [
    { id: 3, title: "Zone One", zone: 1 },
    { id: 4, title: "Zone Two", zone: 2 },
    // { id: 1, title: "Top 10" },
  ];

  const sensorPosition15 = [{ id: 3, title: "Zone One", zone: 1 }];

  const windTurbineZones = [{ id: 0, title: "Zone One" }]
  const allWindTurbineZonesAreShown = windTurbineZones.every((_zone, zoneIndex) => windDirectionSelector.checkStatus[zoneIndex])
  const dispatch = useDispatch()

  return (
    <Suspense fallback={<div>loading</div>}>
      <div className="NavBar">
        <Dropdown item simple text="Scene Selection" className="drop-down">
          <Dropdown.Menu>
            <DropDownCustomized
              inputData={targetBuildingsList}
              title="Target Building"
              status={globalSceneValue}
              value={props.value}
              floors="false"
              surroundings="false"
            />
            <DropDownCustomized
              inputData={surroundingList}
              title="Surroundings"
              status={surroundingSceneValue}
              value={props.value}
              floors="false"
              surroundings="true"
            />
          </Dropdown.Menu>
        </Dropdown>
        {floors && <DropDownFloors data={floors} />}
        <Dropdown item simple text="virtual sensors" className="drop-down">
          <Dropdown.Menu>
            <DropDownSunHours
              inputData={sunsensors}
              title="Solar Sensors"
              status={solarSensorSelector}
              wind="false"
              parentTitle="virtual sensors"
            />
            {winddirection?.length > 0 && (
              <DropDownSunHours
                inputData={winddirection}
                title="Wind Sensors"
                status={windDirectionSelector}
                wind="true"
                parentTitle="virtual sensors"
              />
            )}
          </Dropdown.Menu>
        </Dropdown>

        {!["17", "13", "20", "16", "19", "18"].includes(project_id) && (
          shadingSystem?.length > 0 || project_id == 3 ||
          project_id == 14 ||
          project_id == 15 ||
          project_id == 29 ||
          project_id == 52 ||
          newProjectId == 376
        ) && (
            <Dropdown item simple text="Shading data" className="drop-down">
              <Dropdown.Menu>
                <DropDownShading
                  inputData={shadingSystem}
                  title="Shading System"
                  status={windRiskSelector}
                  parentTitle="Shading data"
                />
                {(project_id == 3 ||
                  project_id == 14 ||
                  project_id == 15 ||
                  project_id == 29 ||
                  project_id == 52) && (
                    <DropDownShading
                      inputData={
                        project_id == 3
                          ? sensorPosition
                          : project_id == 14
                            ? sensorPosition14
                            : sensorPosition15
                      }
                      title="Sensor position"
                      status={windRiskSelector}
                    />
                  )}
              </Dropdown.Menu>
            </Dropdown>
          )}
        {energyValueData ? (
          <Dropdown item simple text="Energy data" className="change-width">
            <Dropdown.Menu className="ggg">
              <Dropdown.Header content="Values" />

              {energyValueData.map((value, valueIndex) => {
                return (
                  <>
                    <DropDownEnergyData
                      data={value}
                      valueIndex={valueIndex}
                      parentTitle="Energy data"
                    />
                  </>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        ) : null}

        {(project_id == 17 || project_id == 18) && <DropDownDaylight />}
        {(pvPanelsData || project_id == 86) && (
          <Dropdown item simple text="Renewable Energy" className="drop-down">
            <Dropdown.Menu>
              {pvPanelsData && (
                <Dropdown item simple text='PV Yield' className="drop-down">
                  <Dropdown.Menu className="kkk" style={{
                    transform: "translateY(-8px)"
                  }}>
                    {pvPanelsData?.map((zone) => {
                      return <DropDownEnergyGeneration zone={zone} key={zone} />;
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              )}

              {project_id == 86 &&
                <Dropdown item simple text='Wind Turbine' className="drop-down">
                  <Dropdown.Menu className="kkk" style={{
                    transform: "translateY(-8px)"
                  }}>
                    {windTurbineZones.map((zone, zoneIndex) => {
                      const checked = windTurbineSelector.checkStatus?.[zoneIndex]
                      return (
                        <Dropdown.Item
                          onClick={() => {
                            dispatch(
                              setTurbineZoneShow([zoneIndex, !checked])
                            );
                          }}
                        >
                          <Checkbox
                            label={zone.title}
                            key={zone.id + zone.title}
                            checked={checked}
                          />
                        </Dropdown.Item>
                      )
                    })}

                  </Dropdown.Menu>
                </Dropdown>
              }
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
    </Suspense>
  );
};

export default NavBar;
