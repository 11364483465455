import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: 0,
  filter: [],
  ceilingList: null,
  floorsList: null,
  facadeList: null,
  internalWalls: null,
  scenarioList: null,
  energyValueList: null,
  windDirectionList: null,
  pvPanels: null,
  roofTop: null,
  stairs: null,
  sensorPlacement: null,
  shadingProgression: null,
  windRisk: null,
  stairsList: null,
  doors: null,
  windRiskDirectionsList: [],
  pvPanelsData: null,
  buildingsInfo: [],
  cameraDistanceToTarget: [30, 20, 70],
  cameraTarget: [0, 0, 0],
  defaultCameraTarget: [0, 0, 0],
  cameraPosition: [30, 20, 70],
  defaultCameraPosition: [30, 20, 70],
}


const sceneGlobalSlice = createSlice({
  name: "sceneVar",
  initialState,
  reducers: {
    updateCamera: (state, action) => {
      state.cameraTarget = action.payload
      state.cameraPosition = [0, 1, 2].map(index => action.payload[index] + state.cameraDistanceToTarget[index])
    },
    setV: (state, action) => {
      state.value = action.payload;
    },
    updateFilter: (state, action) => {
      // state.filter.push(action.payload);
      state.filter[0] = action.payload[0];
    },
    resetChosenFilter: (state, action) => {
      // state.filter.push(action.payload);
      state.filter = [];
    },
    setGlobalSceneVariable: (state, action) => {
      state.ceilingList = action.payload[0];
      state.floorsList = action.payload[1];
      state.facadeList = action.payload[2];
      state.internalWalls = action.payload[3];
      state.scenarioList = action.payload[4];
      state.energyValueList = action.payload[5];
      state.windDirectionList = action.payload[6];
      state.pvPanels = action.payload[7];
      state.roofTop = action.payload[8];
      state.stairs = action.payload[9];
      state.sensorPlacement = action.payload[10];
      state.shadingProgression = action.payload[11];
      state.windRisk = action.payload[12];
      state.stairsList = action.payload[13];
      state.doors = action.payload[14];
      state.windRiskDirectionsList = action.payload[15];
      state.pvPanelsData = action.payload[16];
      state.buildingsInfo = action.payload[17];
    },
  },
});
export const {
  setV,
  updateFilter,
  resetChosenFilter,
  setGlobalSceneVariable,
  resetCameraPosition,
  updateCamera
} = sceneGlobalSlice.actions;

export default sceneGlobalSlice.reducer;
